import * as React from "react";
import { useNavigation } from "../../Context/Navigation/Provider";
import { User } from "./User";

export const Dashboard: React.FC = () => {
  return (
    <div>
      <DashboardInner />
    </div>
  );
};

const LazyRecommendedProducts = React.lazy(() =>
  import("./RecommendedProducts").then((module) => ({
    default: module.RecommendedProducts,
  }))
);

const LazyBlueprintFlowTracker = React.lazy(() =>
  import("../BlueprintFlowTracker/BlueprintFlowTracker").then((module) => ({
    default: module.BlueprintFlowTracker,
  }))
);

const LazyMailTemplateGenerator = React.lazy(() =>
  import("../MailTemplateGenerator/MailTemplateGenerator").then((module) => ({
    default: module.MailTemplateGenerator,
  }))
);

const LazySpecialists = React.lazy(() =>
  import("../Specialists/Specialists").then((module) => ({
    default: module.Specialists,
  }))
);

const LazyExperiences = React.lazy(() =>
  import("../Experiences/Experiences").then((module) => ({
    default: module.Experiences,
  }))
);

const LazyLeads = React.lazy(() =>
  import("../Leads/Leads").then((module) => ({
    default: module.Leads,
  }))
);

const LazyPathToHolisticWellbeing = React.lazy(() =>
  import("../PathToHolisticWellbeing/PathToHolisticWellbeing").then(
    (module) => ({
      default: module.PathToHolisticWellbeing,
    })
  )
);

const LazyBlueprintLite = React.lazy(() =>
  import("../BlueprintLite/BlueprintLite").then((module) => ({
    default: module.BlueprintLite,
  }))
);

const LazyGuides = React.lazy(() =>
  import("../Guides/Guides").then((module) => ({
    default: module.Guides,
  }))
);

const DashboardInner: React.FC = () => {
  const navigation = useNavigation();

  switch (navigation) {
    case "User":
      return <User />;
    case "Recommded Products":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyRecommendedProducts />
        </React.Suspense>
      );
    case "Experiences":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyExperiences />
        </React.Suspense>
      );
    case "Products":
      return <div>Products</div>;
    case "Secrets":
      return <div>Secrets</div>;
    case "Specialists":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySpecialists />
        </React.Suspense>
      );
    case "Blueprint Flow Controller":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyBlueprintFlowTracker />
        </React.Suspense>
      );
    case "MailTemplateGenerator":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyMailTemplateGenerator />
        </React.Suspense>
      );
    case "Leads":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyLeads />
        </React.Suspense>
      );
    case "Plan to Holistic Wellbeing":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyPathToHolisticWellbeing />
        </React.Suspense>
      );
    case "BlueprintLite":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyBlueprintLite />
        </React.Suspense>
      );
    case "Guides":
      return (
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyGuides />
        </React.Suspense>
      );
    default:
      return <></>;
  }
};
